import { TypeStore } from "src/services/StoreService";
import FavoriteSharp from "@material-ui/icons/FavoriteSharp";


const CardImage = ({ image }: { image: string }) => {
  return (
    <div className="w-3/12 bg-white flex justify-center items-center">
      <img src={image} className="object-contain align-middle transition-shadow ease-in-out duration-300 shadow-none" />
    </div>
  );
};

const CardContent = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="w-9/12 h-48 flex flex-col p-6">
      <span className="text-black text-base font-semibold tracking-normal leading-5 mb-3">
        {title}
      </span>
      <span className="text-xs tracking-normal leading-4">{description}</span>
    </div>
  );
};

const StoreCard = ({ store }: { store: TypeStore }) => {
  return (
    <div
      className="cursor-pointer h-24 rounded-md bg-white overflow-hidden flex m-2 shadow-xl hover:shadow-2xl transform motion-safe:hover:scale-110"
      onClick={() =>
        window.open(
          process.env.REACT_APP_DOMAIN + "/stores/" + store.uniqueStoreName,
          "_self"
        )
      }
    >
      {store.storeCoverImage && <CardImage image={store.storeCoverImage} />}
      <CardContent
        title={store.storeName}
        description={store.storeDescription}
      />
            <div>
        <FavoriteSharp fontSize="medium" className="text-red-400" /> 3.2k
      </div>
    </div>
  );
};

export default StoreCard;
