// MyGoogleMaps.js
import React from "react";

import * as lodash from "lodash";

import GoogleMapReact from "google-map-react";
import GoogleMapProductMarker from "src/components/SearchProductsPage/GoogleMapProductMarker";
import GoogleMapCenterMarker from "src/components/SearchStoresPage/GoogleMapCenterMarker";
import { TypeProduct } from "src/services/ProductService";

export interface ProductsGoogleMapProps {
  googleMapCenter: GoogleMapReact.Coords;
  products: TypeProduct[];
}

export type ProductsInStore = {
  products: TypeProduct[];
  storeName: string;
  storeLocation: string;
  storeLocationLatitude: number;
  storeLocationLongitude: number;
};

const ProductsGoogleMap = (props: ProductsGoogleMapProps) => {
  const [storeMarkToggle, setStoreMarkToggle] = React.useState(
    // Generate a <ProductId, showMarker> Hashmap
    props.products.reduce(function (map: any, product: TypeProduct) {
      map[product.storeLocation] = false;
      return map;
    }, {})
  );

  const apiHasLoaded = (map: any, maps: any) => {
    console.log("google map api has loaded");
  };

  /**
   * Only show the marker that get clicked, disable others.
   *
   * @param storeLocation
   */
  const onMarkerClick = (storeLocation: string) => {
    const newStoreMarkToggle = lodash.cloneDeep(storeMarkToggle);
    Object.keys(newStoreMarkToggle).forEach(function (key, index) {
      newStoreMarkToggle[key] = false;
    });
    newStoreMarkToggle[storeLocation] = true;
    setStoreMarkToggle(newStoreMarkToggle);
  };

  /**
   * Disable all markers.
   */
  const onClick = (e: GoogleMapReact.ClickEventValue) => {
    
    // Handle a google-map-react bug: onChildClick doesn't work well in full
    // screen mode.
    // https://github.com/google-map-react/google-map-react/issues/863
    if (e.event.target.id.startsWith("marker-")) {
      onMarkerClick(e.event.target.id.replace("marker-", ""));
    }
  };

  return (
    <div className="h-full w-full" id="productsGoogleMap">
      <GoogleMapReact
        center={props.googleMapCenter}
        zoom={13}
        draggable={true}
        resetBoundsOnResize={true}
        onClick={onClick}
        onChildClick={onMarkerClick}
        // onDragEnd={(map) => onDragEnd(map)}
        //onChange={onChange}
        //onChildClick={onStoreMarkerClick}

        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
          libraries: ["places", "geometry"],
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        <GoogleMapCenterMarker
          lat={props.googleMapCenter.lat}
          lng={props.googleMapCenter.lng}
        />

        {
        // Group products by storeLocation or storeName
        lodash
          .values(lodash.groupBy(props.products, "storeLocation"))
          .filter((productList) => productList.length > 0)
          .map((productList) => {
            let productsInStore: ProductsInStore = {
              products: productList,
              storeName: productList.at(0)!.storeName,
              storeLocation: productList.at(0)!.storeLocation,
              storeLocationLatitude: productList.at(0)!.storeLocationLatitude,
              storeLocationLongitude: productList.at(0)!.storeLocationLongitude,
            };
            return productsInStore;
          })
          .map((productsInStore: ProductsInStore) => {
            return (
              <GoogleMapProductMarker
                key={productsInStore.storeLocation}
                productsInStore={productsInStore}
                showMarker={storeMarkToggle[productsInStore.storeLocation]}
                lat={productsInStore.storeLocationLatitude}
                lng={productsInStore.storeLocationLongitude}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};

export default ProductsGoogleMap;
