import MenuToggler from "src/components/Nav/MenuToggler";
import Navigation from "src/components/Nav/Navigation";
import { motion, useCycle, Variants } from "framer-motion";
import { useState } from "react";

const sidebarVariants: Variants = {
  open: {
    clipPath: `circle(30px at 40px 40px)`,
    transition: {
      duration: 0.4,
    },
  },
  closed: {
    clipPath: `circle(30px at 40px 40px)`,
    transition: {
      duration: 0.4,
      delay: 0.4,
    },
  },
};

export default function Nav() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  //const [isOpen, toggleOpen] = useCycle(false, true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    //toggleOpen();
  }

  return (
    <motion.nav initial={false} animate={isNavOpen ? "open" : "closed"} 
      className={`${isNavOpen ? "navBar open" : "navBar close"}`} >
      <MenuToggler toggle={toggleNav} />
      {isNavOpen &&
        <Navigation />
      }
    </motion.nav>
  );
}