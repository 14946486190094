import React from "react";
import { motion } from "framer-motion";
import MenuItem from "src/components/Nav/MenuItem";
import StoreIcon from "@mui/icons-material/Store";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { getCurrentBusinessAccountUser } from "src/utils/cognito";
import { MenuItemProps } from "src/components/Nav/MenuItem";

const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const Navigation = () => {
  const [menuItems, setMenuItems] = React.useState<MenuItemProps[]>([]);

  React.useEffect(() => {
    //user = await CognitoUtils.getCurrentUser();
    const user = getCurrentBusinessAccountUser();
    const menuItemsArray = [
      {
        icon: ShoppingBasketIcon,
        text: "Home",
        link: process.env.REACT_APP_DOMAIN,
      } as MenuItemProps,
      {
        icon: ShoppingBasketIcon,
        text: "Find Products",
        link: process.env.REACT_APP_DOMAIN + "/products",
      } as MenuItemProps,
      {
        icon: StoreIcon,
        text: "Find Stores",
        link: process.env.REACT_APP_DOMAIN + "/stores",
      } as MenuItemProps,
    ];
    if (user) {
      menuItemsArray.push({
        icon: ShoppingBasketIcon,
        text: "Logout",
        link: process.env.REACT_APP_DOMAIN + "/logout",
      } as MenuItemProps);
    } else {
      menuItemsArray.push({
        icon: ShoppingBasketIcon,
        text: "Signin",
        link: process.env.REACT_APP_DOMAIN + "/signin",
      } as MenuItemProps);
    }
    setMenuItems(menuItemsArray);
  }, []);

  return (
    <motion.ul variants={navigationVariants} className="p-5 absolute top-30px">
      {menuItems && menuItems.map((item, i) => (
        <MenuItem key={i} item={item} />
      ))}
    </motion.ul>
  );
};

export default Navigation;
