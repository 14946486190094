// MyGoogleMaps.js
import React from "react";

import GoogleMapReact from "google-map-react";
import GoogleMapStoreMarker from "src/components/SearchStoresPage/GoogleMapStoreMarker";
import GoogleMapCenterMarker from "src/components/SearchStoresPage/GoogleMapCenterMarker";
import { TypeStore } from "src/services/StoreService";

export interface StoresGoogleMapProps {
  googleMapCenter: GoogleMapReact.Coords;
  stores: TypeStore[];
  storeLocations: GoogleMapStoreLocation[];
  onDragEnd: any;
}

export type GoogleMapStoreLocation = {
  storeId: string;
  uniqueStoreName: string;
  storeName: string;
  storeWebsite: string;
  storeDescription: string;
  contactNumber: string;
  contactEmail: string;
  storeLocation: string;
  storeLocationLongitude: number;
  storeLocationLatitude: number;
  show: boolean;
};

const StoresGoogleMap = (props: StoresGoogleMapProps) => {

  const [center, setCenter] = React.useState<GoogleMapReact.Coords>(
    props.googleMapCenter
  );
  const [zoom, setZoom] = React.useState<number>(13);
  const [draggable, setDraggable] = React.useState<boolean>(true);

  const [storeLocations, setStoreLocations] = React.useState<
    GoogleMapStoreLocation[]
  >(props.storeLocations);

  React.useEffect(() => {
    setStoreLocations(props.storeLocations);
  }, [props.storeLocations, storeLocations]);

  React.useEffect(() => {
    setCenter(props.googleMapCenter);
  }, [props.googleMapCenter]);


  const onChange = (value: GoogleMapReact.ChangeEventValue) => {
    //console.log("onChange");
    setCenter(props.googleMapCenter);
    //setZoom(value.zoom);
  };

  const onDragEnd= (map: any) => {
    console.log("onDrag");
    let ne = map.getBounds().getNorthEast();
    let sw = map.getBounds().getSouthWest();
    //setCenter(props.googleMapCenter);
    props.onDragEnd(ne.lng(), ne.lat(), sw.lng(), sw.lat());
    //setZoom(value.zoom);
  };


  const apiHasLoaded = (map: any, maps: any) => {
    console.log("apiHasLoaded");
   //console.log(map.getCenter().lnt);
   // console.log(map.getCenter().lat);
    /*
      let ne = map.getBounds().getNorthEast();
      let sw = map.getBounds().getSouthWest();
      console.log(ne.lat() + ";" + ne.lng());
      console.log(sw.lat() + ";" + sw.lng());
    */
  };

  const onStoreMarkerClick = (uniqueStoreName: string) => {
    storeLocations.forEach((item) => (item.show = false));
    const index = storeLocations.findIndex(
      (item) => item.uniqueStoreName === uniqueStoreName
    );
    storeLocations[index].show = true;
    setStoreLocations(storeLocations);
  };

  return (
    <div className="h-full w-full">
      <GoogleMapReact
        center={center}
        zoom={zoom}
        draggable={draggable}
        onDragEnd={(map) => onDragEnd(map)}
        onChange={onChange}
        onChildClick={onStoreMarkerClick}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
          libraries: ["places", "geometry"],
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
      >
        <GoogleMapCenterMarker
              lat={props.googleMapCenter.lat}
              lng={props.googleMapCenter.lng}/>

        {storeLocations.map((storeLocation) => (
          <GoogleMapStoreMarker
            key={storeLocation.uniqueStoreName}
            lat={storeLocation.storeLocationLatitude}
            lng={storeLocation.storeLocationLongitude}
            store={storeLocation}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default StoresGoogleMap;
