import React from "react";

import {
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

import { TypeStoreCategory } from "src/services/StoreService";

export type StoreCategoryFilterItem = {
  storeCategory: TypeStoreCategory;
  isPicked: boolean;
};

const StoreCategoryFilter = ({
  storeCategoryFilters,
  handleStoreCategoryUpdate,
}: {
  storeCategoryFilters: StoreCategoryFilterItem[];
  handleStoreCategoryUpdate: any;
}) => {
  const [selected, setSelected] = React.useState<string[]>([]);
  const isAllSelected =
    storeCategoryFilters.length > 0 &&
    selected.length === storeCategoryFilters.length;

  const handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const selectedValue: string[] = event.target.value as string[];
    setSelected(selectedValue);
    handleStoreCategoryUpdate(selectedValue);
  };

  return (
    <FormControl>
      <InputLabel id="mutiple-select-label">Select Store Type</InputLabel>
      <Select
        labelId="mutiple-select-label"
        className="w-52 h-11"
        multiple
        value={selected}
        onChange={handleSelectionChange}
        renderValue={(selected) => selected.join(", ")}
      >
        {storeCategoryFilters.map((option) => (
          <MenuItem
            key={option.storeCategory.categoryType}
            value={option.storeCategory.categoryType}
          >
            <ListItemIcon>
              <Checkbox
                checked={
                  selected.indexOf(option.storeCategory.categoryType) > -1
                }
              />
            </ListItemIcon>
            <ListItemText primary={option.storeCategory.categoryType} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StoreCategoryFilter;
