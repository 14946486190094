import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import LandingPage from "src/pages/LandingPage";
import StorePage from "src/pages/StorePage";
import StoreContactUsPage from "src/pages/StoreContactUsPage";
import ProductPage from "src/pages/ProductPage";
import SearchProductsPage from "src/pages/SearchProductsPage";
import SearchStoresPage from "src/pages/SearchStoresPage";
import SignupPage from "src/pages/SignupPage";
import SigninPage from "src/pages/SigninPage";
import NotFoundPage from "src/pages/NotFoundPage";
import { getCurrentBusinessAccountUser } from "src/utils/cognito";

export type RouteType = {
  path: string;
  key: string;
  exact: boolean;
  element: React.ReactNode;
};

export const PROTECTED_ROUTES: RouteType[] = [
  
];

export const UNPROTECTED_ROUTES: RouteType[] = [
  {
    path: "/",
    key: "LANDING_PAGE",
    exact: true,
    element: <LandingPage />,
  },
  {
    path: "/products",
    key: "SEARCH_PRODUCTS_PAGE",
    exact: true,
    element: <SearchProductsPage />,
  },
  {
    path: "/stores",
    key: "SEARCH_PRODUCTS_PAGE",
    exact: true,
    element: <SearchStoresPage />,
  },
  {
    path: "/stores/:unique_store_name",
    key: "STORE_PAGE",
    exact: true,
    element: <StorePage />,
  },
  {
    path: "/stores/:unique_store_name/contact-us",
    key: "STORE_PAGE",
    exact: true,
    element: <StoreContactUsPage />,
  },
  {
    path: "/stores/:unique_store_name/products/:unique_product_name_in_store",
    key: "PRODUCT_PAGE",
    exact: true,
    element: <ProductPage />,
  },
];

export const AUTHENTICATION_ROUTES: RouteType[] = [
  {
    path: "/signup",
    key: "SIGNUP_PAGE",
    exact: true,
    element: <SignupPage />,
  },
  {
    path: "/signin",
    key: "SIGNIN_PAGE",
    exact: true,
    element: <SigninPage />,
  },
];

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */

/*
function RouteWithSubRoutes(route:RouteProps) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      component={props => <route.component {...props} routes={route.routes} />}
    />
  );
}
*/

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */

export type RenderRoutesProps = {
  routes: RouteType[];
};

export function RenderRoutes() {
  const [routes, setRoutes] = React.useState<RouteType[]>([]);

  React.useEffect(() => {
    //user = await CognitoUtils.getCurrentUser();
    const user: CognitoUser | null = getCurrentBusinessAccountUser();
    let newRoutes = UNPROTECTED_ROUTES;
    if (user) {
      newRoutes = newRoutes.concat(PROTECTED_ROUTES);
    } else {
      newRoutes = newRoutes.concat(AUTHENTICATION_ROUTES);
    }
    newRoutes.push({
      path: "*",
      key: "NOT_FOUND",
      exact: true,
      element: <NotFoundPage />,
    });
    setRoutes(newRoutes);
  }, []);

  return (
    <Routes>
      {routes.map((route, i) => {
        return (
          <Route path={route.path} element={route.element} key={route.key} />
        );
      })}
    </Routes>
  );
}
