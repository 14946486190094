import React, { Component } from "react";
import { GoogleMapStoreLocation } from "src/components/SearchStoresPage/StoresGoogleMap";
import GoogleMapStoreInfoWindow from "src/components/SearchStoresPage/GoogleMapStoreInfoWindow";

const GoogleMapStoreMarker = ({
  store,
  lat,
  lng,
}: {
  store: GoogleMapStoreLocation;
  lat: number;
  lng: number;
}) => {
  return (
    <>
      <div
        style={{
          border: "1px solid white",
          borderRadius: "50%",
          height: 15,
          width: 15,
          backgroundColor: "red",
          cursor: "pointer",
          zIndex: 10,
        }}
      />
      {store.show && <GoogleMapStoreInfoWindow store={store} />}
    </>
  );
};

export default GoogleMapStoreMarker;
