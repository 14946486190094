import { Routes, Route, Navigate } from "react-router-dom";

import { RenderRoutes } from "src/config/routes/Routes";

const App = () => {
  return (
      <div>
        <RenderRoutes />
      </div>
  );
};

export default App;