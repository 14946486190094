import React, { Component } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const LocationFinder = ({
  handleLocationUpdate,
}: {
  handleLocationUpdate: any;
}) => {
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    libraries: ["places", "geometry"],
  });

  const locationInputId = "locationInputId";

  let searchInput: HTMLInputElement;

  const autoCompleteInstanceRef = React.useRef<any>(null);

  React.useEffect(() => {
    loader.load().then(() => {
      searchInput = document.getElementById(
        locationInputId
      ) as HTMLInputElement;
      autoCompleteInstanceRef.current = new google.maps.places.Autocomplete(
        searchInput!,
        {
          // restrict your search to a specific type of resultmap
          //types: ["address"],
          // restrict your search to a specific country, or an array of countries
          // componentRestrictions: { country: ['gb', 'us'] },
        }
      );

      autoCompleteInstanceRef.current.addListener(
        "place_changed",
        onPlaceChanged
      );
      //autoCompleteInstanceRef.current.bindTo("bounds", map);
      //console.log(autoCompleteInstanceRef.current);
    });

    // returned function will be called on component unmount
    return () => {
      google.maps.event.clearInstanceListeners(searchInput!);
    };
  }, []);

  const onPlaceChanged = () => {
    const place: google.maps.places.PlaceResult =
      autoCompleteInstanceRef.current.getPlace();
    //console.log(place);
    if (!place.geometry) return;
    handleLocationUpdate(
      place.geometry!.location!.lat(),
      place.geometry!.location!.lng()
    );
  };

  const clearSearchBox = () => {
    searchInput!.value = "";
  };

  return (
    <>
      <input
        id={locationInputId}
        className="text-md w-full h-full m-0 p-0"
        ref={(ref) => {
          searchInput = ref!;
        }}
        type="text"
        //onFocus={clearSearchBox}
        placeholder="Enter a location"
      />
    </>
  );
};

export default LocationFinder;
