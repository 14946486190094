import React from "react"

import SigninForm from 'src/components/Auth/SigninForm';

const SigninPage = () => {

  return (
        <> 
            <SigninForm/>
        </>
  );
}

export default SigninPage;