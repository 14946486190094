import React from 'react';
import ReactDOM from 'react-dom';

import { ReactNotifications} from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";
import { BrowserRouter} from "react-router-dom";
import Nav from "src/components/Nav";
import 'src/assets/styles/output.css'; // Pord release
import App from 'src/App';
import "react-image-gallery/styles/css/image-gallery.css";


ReactDOM.render(
  <React.StrictMode>
    <ReactNotifications />
    <Nav/>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);