import React, { Component } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { TypeProduct } from "src/services/ProductService";

import { ProductsInStore } from "src/components/SearchProductsPage/ProductsGoogleMap";

const GoogleMapMarker = ({
  productsInStore,
}: {
  productsInStore: ProductsInStore;
}) => {
  return (
      <div 
        id={"marker-" + productsInStore.storeLocation}
        className="cursor-pointer bg-green-300 p-1 w-6 rounded-full text-center">
        {productsInStore.products.length}
      </div>
  );
};

const ProductsDisplayWindow = ({
  productsInStore,
}: {
  productsInStore: ProductsInStore;
}) => {
  const imageGalleryItems: ReactImageGalleryItem[] | null =
    productsInStore.products.map((product): ReactImageGalleryItem => {
      return {
        original: product.productImageUrls[0],
        thumbnail: product.productImageUrls[0],
        sizes : '(max-width: 20px) 20px, 50vw',
      };
    });

  return (
    <div className="relative w-56 text-sm bg-white p-2 z-50 ">
      <div className="text-xs">{productsInStore.storeName}</div>
      <div className="">
        <ImageGallery 
          items={imageGalleryItems} 
          showThumbnails={false}  />
      </div>
    </div>
  );
};

const GoogleMapProductMarker = ({
  productsInStore,
  showMarker,
  lat,
  lng,
}: {
  productsInStore: ProductsInStore;
  showMarker: boolean;
  lat: number;
  lng: number;
}) => {
  return (
    <>
      <GoogleMapMarker productsInStore={productsInStore} />
      {showMarker && (
        <ProductsDisplayWindow productsInStore={productsInStore} />
      )}
    </>
  );
};

export default GoogleMapProductMarker;
