import StoreCard from "src/components/SearchStoresPage/StoreCard";
import { TypeStore } from "src/services/StoreService";

const StoreCardsContainer = ({ storeList }: { storeList: TypeStore[] }) => {
  return (
    <div className="p-5 flex-row flex-wrap">
      {storeList.map((store) => (
        <StoreCard 
        key={store.storeId}
        store={store} />
      ))}
    </div>
  );
};

const StoreList = ({ storeList }: { storeList: TypeStore[] }) => {
  return <StoreCardsContainer storeList={storeList} />;
};

export default StoreList;
