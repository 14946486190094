import React from "react";

import { useParams } from "react-router-dom";
import Navbar from "../components/StorePage/StorePageNavbar";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { getProductInfoFromStore, TypeStore, getStoreInfo} from "src/services/StoreService";
import {TypeProduct} from "src/services/ProductService";

const ProductInfoContainer = ({product} : {product : TypeProduct}) => {
  return (
      <div className="items-center ml-20">
        <h1 className="text-3xl font-bold">{product.productName}</h1>
        <p className="mt-8 text-base col-span-full">{product.productDescription}</p>
        <div className="mt-8 text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400  via-yellow-600 to-yellow-800">$ {product.priceInDollar}</div>
      </div>
  );
}

const Product = () => {

  const pathParameters: any = useParams();
  const [product, setProduct] = React.useState<TypeProduct | null>({} as TypeProduct);
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);

    // Get products for this store.
  React.useEffect(
      () => {
          const uniqueStoreNameParam:string = pathParameters.unique_store_name;
          const uniqueProductNameParam:string = pathParameters.unique_product_name_in_store;
          getProductInfoFromStore(uniqueStoreNameParam, uniqueProductNameParam)
              .then(response => setProduct(response))
              .catch(error => console.log(error));
      }, 
      [setProduct]
  );

  // Get store info.
  React.useEffect(
      () => {
          const uniqueStoreNameParam:string = pathParameters.unique_store_name;
          getStoreInfo(uniqueStoreNameParam)
              .then(response => setStore(response))
              .catch(error => console.log(error));  
      }, 
      [setStore]
  );

  const imageGalleryItems: ReactImageGalleryItem[] | null 
  = product && product.productImageUrls && product.productImageUrls.map(
    (imgUrl : string ) : ReactImageGalleryItem => {
      return {
        original: imgUrl,
        thumbnail: imgUrl
      };
    }
  );

  return (
    <div>
      <Navbar store={store!} />

      <div className="flex">
        { imageGalleryItems &&
          <div className="max-w-2xl">
          <ImageGallery 
            
            items={imageGalleryItems} />
          </div>
        }
        { product &&
          <ProductInfoContainer product={product}/>
        }
      </div>
    </div>
  );
};

export default Product;
