import React, { Component } from "react";

const GoogleMapCenterMarker = ({
  lat,
  lng,
}: {
  lat: number;
  lng: number;
}) => {
    return (
        <>
          <div className="animate-pulse cursor-pointer z-10 bg-contain h-3 w-3 rounded-md bg-blue-500">

          </div>
        </>
      );
}

export default GoogleMapCenterMarker;