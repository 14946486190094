
import React from "react";
import { Badge } from "@material-ui/core";
import { Search, ShoppingCartOutlined } from "@material-ui/icons";
import {openInNewTab} from "src/utils/helperFunctions";
import { TypeStore } from "src/services/StoreService";
import styled from "styled-components";
import { mobile } from "../../responsive";
import {
  Facebook,
  Instagram,
  Twitter,
} from "@material-ui/icons";

const Container = ({store} : {store: TypeStore}) => {
  return (
    <div>
      <Wrapper store={store}/>
    </div>
  );
}

const Wrapper = ({store} : {store: TypeStore}) => {
  return (
    <div className="flex justify-content justify-between  border-b-4  border-blue-300 p-3">
      <Left/>
      <Center store={store}/>
      <Right store={store}/>
    </div>
  );
}

const Left = () => {
  return (
    <div className="flex items-center w-1/5">
      
    </div>
  );
}

const Center = ({store} : {store: TypeStore}) => {
  return (
    <div className="items-center justify-center w-3/5">
        <Logo logo={store.storeName} unique_store_name={store.uniqueStoreName}/>
        <SocialContainer store={store}/>
        <div className="flex items-center justify-center"> {store.storeDescription} </div>
    </div>
  );
}

const OperationLink = ({
  linkUrl,
  linkText,
}: {
  linkUrl: string;
  linkText: string;
}) => {
  return (
    <a
      href={linkUrl}
      className="hover:underline text-blue-600 hover:text-blue-800 visited:text-purple-600 block"
    >
      {linkText}
    </a>
  );
};


const Right = ({store} : {store: TypeStore}) => {
  const contactUsLink = process.env.REACT_APP_DOMAIN + "/stores/" + store.uniqueStoreName + "/contact-us";

  return (
    <div className="flex items-center justify-end w-1/5">
        <div>
          <OperationLink
            linkUrl={contactUsLink}
            linkText="Contact Us"
          />
        </div>
        <MenuItem>
          <Badge badgeContent={4} color="primary">
            <ShoppingCartOutlined />
          </Badge>
       </MenuItem>
    </div>
  );
}

const SearchContainer = () => {
  return (
    <div className="flex items-center ml-6 p-1 border-2 border-gray-300">
        <input placeholder="Search" className=" bg-white h-8 px-5 pr-16 rounded-lg text-base focus:outline-none"/>
        <Search style={{ color: "gray", fontSize: 22 }} />
    </div>
  );
}

const Logo = ({logo, unique_store_name} : {logo: string, unique_store_name: string}) => {
  return (
    <div className="flex items-center justify-center text-3xl cursor-pointer hover:opacity-60" 
      onClick={() => window.open(process.env.REACT_APP_DOMAIN + "/stores/" + unique_store_name, "_self")}> 
      {logo} 
    </div>
  );
}

const SocialContainer = ({store} : {store: TypeStore}) => {
  return (
    <div className="flex items-center justify-center">
    {
      store.facebookLink && 
      <div 
        className=" text-blue-500  hover:opacity-60 hover:scale-125 m-0"
        onClick= {event => openInNewTab(store.facebookLink)}>
        <Facebook />
      </div>
    }
    {
      store.instagramLink &&
      <div 
        className=" text-pink-500 hover:opacity-60 hover:scale-125 m-0"
        onClick= {event => openInNewTab(store.instagramLink)}>
        <Instagram />
      </div>
    }
    {
      store.twitterLink &&
      <div 
          className=" text-blue-700 hover:opacity-60 hover:scale-125 m-0"
          onClick={event => openInNewTab(store.twitterLink)}>
        <Twitter />
      </div>
    }
    </div>
  );
}



const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 25px;
  ${mobile({ fontSize: "12px", marginLeft: "10px" })}
`;

const StorePageNavbar = ({store} : {store: TypeStore}) => {
  return (
    <Container store={store}/>
  );
};

export default StorePageNavbar;
