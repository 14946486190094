import LocationFinder from "src/components/GoogleMap/LocationFinder";
import { LocationOn } from "@material-ui/icons";
import SearchIcon from "@mui/icons-material/Search";

const SearchContainer = ({
  handleLocationUpdate,
}: {
  handleLocationUpdate: any;
}) => {
  return (
    <div className="flex items-center justify-center h-10 w-1/3 border-2">
        <LocationFinder handleLocationUpdate={handleLocationUpdate} />
        <LocationOn
          style={{
            color: "gray",
            fontSize: 22,
            flex: 1,
            margin: 0,
            padding: 0,
            width: 10,
          }}
        />
    </div>
  );
};

export default SearchContainer;
