import React from "react";
import { Toast } from "src/utils/notifications";
import { signUpBusinessAccount } from "src/services/AuthService";
import { useNavigate } from "react-router-dom";
import {Formik,Form, ErrorMessage} from 'formik';
import * as Yup from "yup"

const SignupForm = () => {
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const prefferedUsernameRegularExp = /^[a-zA-Z0-9]+$/;
  const emailRegularExp = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
  const phoneNumberRegularExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/
  const validationSchema = Yup.object().shape({
    preferred_username: Yup.string()
      .min(3,'Should be ast least 3 characters long')
      .max(50,'should not exceed 50 characters')
      .matches(prefferedUsernameRegularExp, "Only alphabets, numbers are allowed.")
      .required('Please enter username.'),

    email: Yup.string()
      .min(3,'Should be 3 character long')
      .max(100,'should not exceed 100 characters')
      .matches(emailRegularExp, "Please enter a valid email address.")
      .required('Please enter email.'),

    phone_number: Yup.string()
        .required("Please enter a valid phone number.")
        .matches(
          phoneNumberRegularExp,
            'Phone number must be +14325551212 format.'
        ),
    password: Yup.string()
      .min(3,'Should be 3 character long')
      .max(20,'should not exceed 20 characters')
      .required('Please enter password.'),
    confirmPassword: Yup.string()
      .test('passwords-match', 'Passwords must match', function(value){
      return this.parent.password === value
    })
  });

  const initialValues = {
    preferred_username: "Your username",
    email: "Your email",
    phone_number: "Your phone number e.g. +14325551212",
    password: "Your password",
    confirmPassword: "confirm password",
  };


  const handleSignUp = async(event: any) => {

    setLoading(true);

    if (event.password !== event.confirmPassword) {
      Toast(
        "Error!!",
        "Password and Confirm Password should be same",
        "danger"
      );
      return;
    }
    try {
      await signUpBusinessAccount(
        event.email,
        event.confirmPassword
      );
      Toast("Success!!", "Signup was successful", "success");
      navigate("/confirmation");
    } catch (error : any) {
        if (error.name === 'UsernameExistsException') {
          Toast("User exists. Please login.", error.message, "info");
          navigate("/signin");  
        } else {
          Toast("Error!!", error.message, "danger");
        }
    }
    setLoading(false);
  };


  return (
    <div className="flex justify-center items-center">
      <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSignUp}
      >
        {
          ({ handleSubmit, handleChange, handleBlur, values, errors  }) => {
              return (
                <Form className="w-1/3">
                  <div className="form-group">
                    <label htmlFor="preferred_username" className="form-label"> Username </label>
                    <input name="preferred_username" type="text" value={values.preferred_username}
                      onChange={handleChange} onBlur={handleBlur} className="form-textInput w-full" />
                    <ErrorMessage
                      name="preferred_username"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email" className="form-label"> Email </label>
                    <input name="email" type="email" value={values.email}
                      onChange={handleChange} onBlur={handleBlur}  className="form-textInput w-full" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone_number" className="form-label"> Phone Number </label>
                    <input name="phone_number" type="phone" value={values.phone_number}
                      onChange={handleChange} onBlur={handleBlur}  className="form-textInput w-full" />
                    <ErrorMessage
                      name="phone_number"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password" className="form-label"> Password </label>
                    <input name="password" type="password" 
                      onChange={handleChange} onBlur={handleBlur}  className="form-textInput w-full"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword" className="form-label"> Confirm Password </label>
                    <input name="confirmPassword" type="password"
                      onChange={handleChange} onBlur={handleBlur}  className="form-textInput w-full"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group my-6 flex items-center justify-center">
                    <button type="submit" className="form-submitButton">
                      Sign up
                    </button>
                  </div>
                </Form>
            );
          }
        }
      </Formik>
    </div>
  );
};

export default SignupForm;