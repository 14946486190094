import React from "react"

import SignupForm from 'src/components/Auth/SignupForm';

const SignupPage = () => {

  return (
        <> 
            <SignupForm/>
        </>
  );
}

export default SignupPage;