import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

/***********************************************************
 * General Cognito authentication
 *************************************************************/

/**
 * create cognito user pool from the pool parameters
 * @return {CognitoUserPool} Cognito Pool object
 */
export function getPool(userPoolId: string, userPoolClientId: string) {
  var poolData = { UserPoolId: userPoolId, ClientId: userPoolClientId };
  return new CognitoUserPool(poolData);
}

/**
 * obtains current cognito user from the current pool
 * @return {CognitoUserPool} Cognito user object
 */
export function getUser(userPoolId: string, userPoolClientId: string): CognitoUser | null {
  //console.log(getPool(userPoolId, userPoolClientId).getCurrentUser());

  return getPool(userPoolId, userPoolClientId).getCurrentUser();
}

/**
 * obtains current cognito session token from the current user
 * @param {CognitoUser} user - the actual user
 * @param {string} [opt = session] - the type of response expected
 * @return {string||Object} the session info, wether a token or the whole session data
 */
 export function getSession(user: CognitoUser, opt = "session") {
  return new Promise((resolve, reject) => {
    user.getSession((error: any, session: any) => {
      if (error) {
        reject(error);
        return;
      }

      if (opt === "session") resolve(session);
      else if (opt === "id") resolve(session.getIdToken().getJwtToken());
      else if (opt === "user") resolve(user);
    });
  });
}

/**
 * ends cognito session for the retrieved user
 * @param {CognitoUser} user - the user to logout
 * @param {boolean} [doGlobal = false] - the signout must be global or not
 */
 export function signOut(user: CognitoUser, doGlobal = false) {
  if (doGlobal)
    user.globalSignOut({
      onSuccess: (result) => {
        //success
      },
      onFailure: (err) => {
        //err;
      },
    });
  else user.signOut();
}

/**
 * execute the Sign In action
 * @param {string} email - the user email
 * @param {string} password - the user password
 * @param {CognitoUser} user - the CognitoUser object to sign in
 * @return {Promise<Object>} the session object
 */
 export function signIn(email: string, password: string, user: CognitoUser) {
  var authenticationData = {
    Username: email,
    Password: password,
  };
  var authenticationDetails = new AuthenticationDetails(authenticationData);
  return new Promise((resolve, reject) => {
    user.authenticateUser(authenticationDetails, {
      onSuccess: (result) => resolve(result),
      onFailure: (error) => reject(error),
    });
  });
}

/**
 * execute the forgot password action
 * @param {Object} user - the user to forgot password
 * @return {Promise<Object>} confirmation of the operation
 */
 export function forgotPassword(user: CognitoUser) {
  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: (data: any) => resolve(data),
      onFailure: (error: any) => reject(error),
    });
  });
}

/**
 * execute the recover password action
 * @param {string} password - the user new password
 * @param {string} code - the recovery code
 * @param {CognitoUser} user - the user to recover password
 * @return {Promise<Object>} confirmation of the operation
 */
 export function recoverPassword(password: string, code: string, user: CognitoUser) {
  return new Promise((resolve, reject) => {
    user.confirmPassword(code, password, {
      onSuccess: (data) => resolve(data),
      onFailure: (error) => reject(error),
    });
  });
}

/**
 * execute the send verification email action
 * @param {CognitoUser} user - the user to sen verification
 * @return {Promise<Object>} confirmation of the operation
 */
 export function sendVerifyEmail(user: CognitoUser) {
  return new Promise((resolve, reject) => {
    user.getAttributeVerificationCode("email", {
      onSuccess: (data) => resolve(data),
      onFailure: (error) => reject(error),
    });
  });
}

/**
 * execute the email verification action
 * @param {string} code -  the verification code
 * @param {CognitoUser} user - the user to sen verification
 * @return {Promise<Object>} confirmation of the operation
 */
 export function verifyEmail(code: string, user: CognitoUser) {
  return new Promise((resolve, reject) => {
    user.verifyAttribute("email", code, {
      onSuccess: (data: any) => resolve(data),
      onFailure: (error: any) => reject(error),
    });
  });
}

/***********************************************************
 * Business account related
 *************************************************************/

/**
 * obtains current cognito user from the current pool
 * @return {CognitoUser} Cognito user object
 */
export function getCurrentBusinessAccountUser() : CognitoUser | null{
  return getUser(
    process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_ID!,
    process.env.REACT_APP_BUSINESS_ACCOUNT_USER_POOL_CLIENT_ID!
  );
}

