import React from "react";

import cloneDeep from "lodash/cloneDeep";
import GoogleMapReact from "google-map-react";
import googleMapLoading from "src/assets/images/googleMapLoading.gif";
import StoreList from "src/components/SearchStoresPage/StoreList";
import {
  TypeStore,
  SearchStoresParameters,
  searchStores,
  TypeStoreCategory,
  getStoreCategories,
} from "src/services/StoreService";
import { GoogleMapStoreLocation } from "src/components/SearchStoresPage/StoresGoogleMap";
import StoreCategoryFilter, {
  StoreCategoryFilterItem,
} from "src/components/SearchStoresPage/StoreCategoryFilter";
import StoresGoogleMap from "src/components/SearchStoresPage/StoresGoogleMap";
import SearchContainer from "src/components/GoogleMap/SearchContainer";

interface StateProperties {
  stores: TypeStore[];
  storeLocations: GoogleMapStoreLocation[];
}

const SearchStoresPage = () => {
  /**
   * Use React state here to make sure that whever storesState change, the
   * component re-rendered.
   */
  const [storesState, setStoresState] = React.useState<StateProperties>({
    stores: [],
    storeLocations: [],
  });

  /**
   * Use React state here to make sure that whever storeCategoryFilters change,
   * the component re-rendered.
   */
  const [storeCategoryFilters, setStoreCategoryFilters] = React.useState<
    StoreCategoryFilterItem[]
  >([]);

  /**
   * Use React state here to make sure that whever searchStoresParameters change,
   * a new store search request will be sent to update storesState.
   */
  const [searchStoresParameters, setSearchStoresParameters] =
    React.useState<SearchStoresParameters>({});

  const [googleMapCenter, setGoogleMapCenter] =
    React.useState<GoogleMapReact.Coords>();

  const searchStoresFunc = (searchStoresParameters: SearchStoresParameters) => {
    searchStores(searchStoresParameters)
      .then((response) => {
        const locations: GoogleMapStoreLocation[] = response.map(
          (obj: TypeStore) => {
            return {
              storeId: obj["storeId"],
              storeName: obj["storeName"],
              uniqueStoreName: obj["uniqueStoreName"],
              storeDescription: obj["storeDescription"],
              storeWebsite: obj["storeWebsite"],
              contactNumber: obj["contactNumber"],
              contactEmail: obj["contactEmail"],
              storeLocation: obj["storeLocation"],
              storeLocationLatitude: obj["storeLocationLatitude"],
              storeLocationLongitude: obj["storeLocationLongitude"],
              show: false,
            };
          }
        );
        setStoresState({ stores: response, storeLocations: locations });
      })
      .catch((error) => console.log(error));
  };

  /**
   * Fetch data when first render the component.
   */
  React.useEffect(() => {
    // Find current location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const coordinates: GoogleMapReact.Coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setGoogleMapCenter(coordinates);
        }
      );
    }
    // Get stores information
    searchStoresFunc(searchStoresParameters);

    // Get store category information
    getStoreCategories()
      .then((response) => {
        const store_categories: StoreCategoryFilterItem[] = response.map(
          (typeStoreCategory: TypeStoreCategory) => {
            return {
              storeCategory: typeStoreCategory,
              isPicked: false,
            };
          }
        );
        setStoreCategoryFilters(store_categories);
      })
      .catch((error) => console.log(error));
  }, []);

  /**
   * When googleMapCenter changed, usually that means the search location is
   * changed, search stores based on the specified location.
   */
  React.useEffect(() => {
    if (googleMapCenter && googleMapCenter.lat && googleMapCenter.lng) {
      let newSearchStoresParameters: SearchStoresParameters = {
        //storeCategories?: string;
        latitude: googleMapCenter.lat.toString(),
        longitude: googleMapCenter.lng.toString(),
      };
      setSearchStoresParameters(newSearchStoresParameters);
    }
  }, [googleMapCenter]);

  const handleStoreCategoryUpdate = (selectedCategories: string[]) => {
    const newSearchStoresParameters: SearchStoresParameters = {};
    if (selectedCategories.length > 0) {
      newSearchStoresParameters.storeCategories = selectedCategories.toString();
    }
    setSearchStoresParameters(newSearchStoresParameters);
  };

  /**
   * Search stores whenever searchStoresParameters state changed.
   */
  React.useEffect(() => {
    searchStoresFunc(searchStoresParameters);
  }, [searchStoresParameters]);

  /**
   * When location updated, re-set the google map center. That will triger
   * a search based on the new google map center.
   *
   * @param newLatitude
   * @param newLongitude
   */
  const handleLocationUpdate = (newLatitude: number, newLongitude: number) => {
    setGoogleMapCenter({
      lat: newLatitude,
      lng: newLongitude,
    });
  };

  const onDragEnd = (
    northEastLongitude: number,
    northEastLatitude: number,
    southWestLongitude: number,
    southWestLatitude: number
  ) => {
    const newSearchStoresParameters: SearchStoresParameters = {
      neLat: northEastLatitude.toString(),
      neLng: northEastLongitude.toString(),
      swLng: southWestLongitude.toString(),
      swLat: southWestLatitude.toString(),
    };

    setSearchStoresParameters(newSearchStoresParameters);
  };

  return (
    <div className="flex-row">
      <div className="flex flex-row ml-10 items-center w-fit ">
        <StoreCategoryFilter
          storeCategoryFilters={storeCategoryFilters}
          handleStoreCategoryUpdate={handleStoreCategoryUpdate}
        />
        <SearchContainer handleLocationUpdate={handleLocationUpdate} />

        {/* <span>
          {googleMapCenter?.lat} : {googleMapCenter?.lng}
        </span>
        */}
      </div>
      <div className="flex flex-row w-2/3 items-center justify-center">
        <StoreList storeList={storesState.stores} />
      </div>
      <div className="w-1/4 h-full absolute top-0 right-0">
        {!googleMapCenter && <img src={googleMapLoading} />}
        {googleMapCenter && (
          <StoresGoogleMap
            googleMapCenter={googleMapCenter}
            storeLocations={storesState.storeLocations}
            stores={storesState.stores}
            onDragEnd={onDragEnd}
          />
        )}
      </div>
    </div>
  );
};

export default SearchStoresPage;
