
const AnnouncementContainer = () => {
  return (
    <div className="h-8 bg-red-50 text-black flex items-center justify-center text-sm font-medium">
      Super Deal! Free Shipping on Orders Over $50
    </div>
  );
}

const Announcement = () => {
  return <AnnouncementContainer/>;
};

export default Announcement;
