import React, { Component } from "react";
import { GoogleMapStoreLocation } from "src/components/SearchStoresPage/StoresGoogleMap";

const GoogleMapStoreInfoWindow = ({
  store,
}: {
  store: GoogleMapStoreLocation;
}) => {
  const onClick = () => {
    window.open(
      process.env.REACT_APP_DOMAIN + "/stores/" + store.uniqueStoreName,
      "_self"
    );
  };

  return (
    <div
      className="relative w-56 text-sm bg-white p-2 z-50 shadow-2xl"
      onClick={onClick}
    >
      <div style={{ fontSize: 16 }}>{store.storeName}</div>
      <div style={{ fontSize: 14, color: "grey" }}>{store.storeLocation}</div>
    </div>
  );
};

export default GoogleMapStoreInfoWindow;
