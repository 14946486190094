import React from "react";

import { TypeProduct } from "src/services/ProductService";
import FavoriteSharp from "@material-ui/icons/FavoriteSharp";

const ImageDiv = ({ product_image_urls }: { product_image_urls: string[] }) => {
  if (product_image_urls.length > 0) {
    return (

        <img 
          className="w-50 h-50 mx-auto object-fit"
          src={product_image_urls[0]} />
    );
  } else {
    return null;
  }
};

const SoldBy = ({ product }: { product: TypeProduct }) => {
  return (
    <div className="text-xs text-center">
      Sold by{" "}
      <a
        href={
          process.env.REACT_APP_DOMAIN + "/stores/" + product.uniqueStoreName
        }
        className="underline text-blue-600 hover:text-indigo-600"
      >
        {product.storeName}
      </a>
    </div>
  );
};

const ProductInfo = ({ product }: { product: TypeProduct }) => {
  return (
    <div className="items-center text-xs ">
      <div className="text-center">$ {product.priceInDollar}</div>
      <div className="text-center">{product.productName}</div>
    </div>
  );
};

const ProductCardContainer = ({
  cardLink,
  product,
}: {
  cardLink: string;
  product: TypeProduct;
}) => {
  return (
    <div
      className="justify-center w-1/6 h-1/6 m-3 shadow-md cursor-pointer hover:shadow-2xl"
      onClick={() => window.open(cardLink, "_self")}
    >
      <ImageDiv product_image_urls={product.productImageUrls} />
      <ProductInfo product={product} />
      <SoldBy product={product} />
      <div>
        <FavoriteSharp className="text-red-400" fontSize="small" /> 
        <span className="text-sm">8.9k</span>
      </div>
    </div>
  );
};

export interface ProductProps {
  product: TypeProduct;
  cardLink: string;
}

const ProductCard = (props: ProductProps) => {
  return (
    <ProductCardContainer cardLink={props.cardLink} product={props.product} />
  );
};

export default ProductCard;
