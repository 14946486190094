import React from "react";

import GoogleMapReact from "google-map-react";
import ProductsGoogleMap from "src/components/SearchProductsPage/ProductsGoogleMap";
import { Search, LocationOn } from "@material-ui/icons";
import ProductsList from "src/components/SearchProductsPage/ProductList";
import {
  TypeProduct,
  searchProducts,
  SearchProductsParameters,
} from "src/services/ProductService";
import SearchContainer from "src/components/GoogleMap/SearchContainer";

import googleMapLoading from "src/assets/images/googleMapLoading.gif";

const SearchProductsPage = () => {
  const [products, setProducts] = React.useState<TypeProduct[]>([]);

  const [googleMapCenter, setGoogleMapCenter] =
    React.useState<GoogleMapReact.Coords>();

  /**
   * Use React state here to make sure that whenever searchProductsParameters change,
   * a new store search request will be sent to update storesState.
   */
  const [searchProductsParameters, setSearchProductsParameters] =
    React.useState<SearchProductsParameters>({});

  /**
   * When location updated, re-set the google map center. That will triger
   * a search based on the new google map center.
   *
   * @param newLatitude
   * @param newLongitude
   */
  const handleLocationUpdate = (newLatitude: number, newLongitude: number) => {
    setGoogleMapCenter({
      lat: newLatitude,
      lng: newLongitude,
    });
    let newSearchProductsParameters: SearchProductsParameters = {
      //storeCategories?: string;
      latitude: newLatitude.toString(),
      longitude: newLongitude.toString(),
    };
    console.log(newSearchProductsParameters);
    setSearchProductsParameters(newSearchProductsParameters);
  };

  /**
   * First render.
   */
  React.useEffect(() => {
    // Find current location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const coordinates: GoogleMapReact.Coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const newSearchProductsParameters: SearchProductsParameters = {
            latitude: position.coords.latitude.toString(),
            longitude: position.coords.longitude.toString(),
          };
          setGoogleMapCenter(coordinates);
          setSearchProductsParameters(newSearchProductsParameters);
        }
      );
    }

    searchProducts(searchProductsParameters)
      .then((response) => setProducts(response))
      .catch((error) => console.log(error));
  }, []);

  /**
   * Make a new products search whenever searchProductsParameters state changed.
   */
  React.useEffect(() => {
    searchProducts(searchProductsParameters)
      .then((response) => setProducts(response))
      .catch((error) => console.log(error));
  }, [searchProductsParameters]);

  return (
    <div className="flex-row">
      <div className="ml-8">
        <SearchContainer handleLocationUpdate={handleLocationUpdate} />
      </div>

      <div className="flex flex-row w-3/4 items-center">
        <ProductsList productList={products} />
      </div>
      <div className="w-1/4 h-full absolute top-0 right-0">
        {!googleMapCenter && <img src={googleMapLoading} />}
        {googleMapCenter && (
          <ProductsGoogleMap
            googleMapCenter={googleMapCenter}
            products={products}
          />
        )}
      </div>
    </div>
  );
};

export default SearchProductsPage;
