
import { Search } from "@material-ui/icons";

import {
  Facebook,
  Instagram,
  Phone,
  Twitter,
} from "@material-ui/icons";

const Container = () => {
  return (
    <div className="border-b-2 pb-14">
      <Wrapper />
    </div>
  );
}

const Wrapper = () => {
  return (
    <div className="h-10 flex justify-content justify-between p-3">
      <Left/>
      <Center/>
      <Right/>
    </div>
  );
}

const Left = () => {
  return (
    <div className="flex items-center w-1/5 m-5">
    </div>
  );
}

const Center = () => {
  return (
    <div className="items-center justify-center w-3/5 m-5">
        <SocialContainer />
    </div>
  );
}

const Right = () => {
  return (
    <div className="flex items-center justify-end w-1/5 m-5">
    </div>
  );
}

const Logo = () => {
  return (
    <div className="flex items-center ml-6 p-1 border-2 border-gray-300 object-fill">
        <img src="https://cdn.logo.com/hotlink-ok/logo-social.png" alt="Logo" width="80" height="80" />
    </div>
  );
}


const SocialContainer = () => {
  return (
    <div className="flex items-center justify-center">
      <div 
        className="w-10 h-10 text-blue-500  hover:opacity-60 hover:scale-125"
        onClick={event =>  window.location.href="store.facebook_link!"} >
        <Facebook />
      </div>
      <div 
        className="w-10 h-10 text-pink-500"
        onClick={event =>  "window.location.href=store.instagram_link!"}>
        <Instagram />
      </div>
      <div 
          className="w-10 h-10 text-blue-700"
          onClick={event =>  window.location.href="store.twitter_link!"}>
        <Twitter />
      </div>
    </div>
  );
}


const LandingPageHeader = () => {
  return (
    <Container/>
  );
};

export default LandingPageHeader;
