import React from "react";
import { useParams } from "react-router-dom";
import Announcement from "src/components/Announcement";
import StorePageNavbar from "src/components/StorePage/StorePageNavbar";
import ProductsList from "src/components/SearchProductsPage/ProductList";
import {
  TypeStore,
  getStoreInfo,
  getProductsFromStore,
} from "src/services/StoreService";
import { TypeProduct } from "src/services/ProductService";

const StorePage = () => {
  const pathParameters: any = useParams();
  const [store, setStore] = React.useState<TypeStore | null>({} as TypeStore);
  const [products, setProducts] = React.useState<TypeProduct[]>([]);

  // Get products for this store.
  React.useEffect(() => {
    const uniqueStoreNameParam: string = pathParameters.unique_store_name;
    getProductsFromStore(uniqueStoreNameParam)
      .then((response) => setProducts(response))
      .catch((error) => console.log(error));
  }, [setProducts]);

  // Get store info.
  React.useEffect(() => {
    const uniqueStoreNameParam: string = pathParameters.unique_store_name;
    getStoreInfo(uniqueStoreNameParam)
      .then((response) => setStore(response))
      .catch((error) => console.log(error));
  }, [setStore]);

  return (
    <div>
      <StorePageNavbar store={store!} />
      <Announcement />
      <ProductsList productList={products} />
    </div>
  );
};

export default StorePage;
