
export type TypeProduct = {
    productId: string,
    productName: string,
    uniqueProductNameInStore: string,
    uniqueStoreName: string,
    storeName: string
    productDescription: string,
    priceInDollar: number,
    productBrand: string,
    productBrandWebsite: string,
    productBrandDescription: string,
    productCategory: string,
    productImageUrls: string[],
    storeLocation: string;
    storeLocationLatitude: number,
    storeLocationLongitude: number,
    timestamp: string
}

/**
 * A type contains search stores API parameters.
 */
 export type SearchProductsParameters = {
    productCategories?: string;
    latitude?: string;
    longitude?: string;
    meters?: string;
    neLat?: string;
    neLng?: string;
    swLat?: string;
    swLng?: string;
  };

export const searchProducts = async (searchProductsParameters: SearchProductsParameters) => {
    let searchProductsApi = process.env.REACT_APP_BACKEND_SERVICE_API + "/products";
    let urlParams = new URLSearchParams(searchProductsParameters).toString();
    if (urlParams.length > 0) {
        searchProductsApi += '?' + urlParams;
    }
    const res = await fetch(
        searchProductsApi,
    );
    const json = await res.json();
    const productList: TypeProduct[] = json.map( 
        (obj:any) => {
            return (
            {
                productId: obj['productId'],
                productName: obj['productName'],
                uniqueProductNameInStore: obj['uniqueProductNameInStore'],
                uniqueStoreName: obj['uniqueStoreName'],
                storeName: obj['storeName'],
                productDescription: obj['productDescription'],
                priceInDollar: obj['priceInDollar'],
                productBrand: obj['productBrand'],
                productBrandWebsite: obj['productBrandWebsite'],
                productBrandDescription: obj['productBrandDescription'],
                productCategory: obj['productCategory'],
                productImageUrls: obj['productImageUrls'],
                storeLocation: obj["storeLocation"],
                storeLocationLatitude: obj["storeLocationLatitude"],
                storeLocationLongitude: obj["storeLocationLongitude"],
                timestamp: obj['timestamp']
            }
        )
        }
    );

    return productList;
}